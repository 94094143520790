import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import { useIntl } from 'react-intl'
import { DatePicker } from 'antd'
import { formatDateText } from 'utils/formatting'

const { RangePicker: RangeAntd } = DatePicker

export const getDefaultPresets = (formatMessage) => [
  {
    label: formatMessage({ id: 'date.yesterday' }),
    value: [dayjs().subtract(1, 'day'), dayjs().subtract(1, 'day')],
  },
  {
    label: formatMessage({ id: 'date.today' }),
    value: [dayjs(), dayjs()],
  },
  {
    label: formatMessage({ id: 'date.this_month' }),
    value: [dayjs().startOf('month'), dayjs().endOf('month')],
  },
  {
    label: formatMessage({ id: 'date.last_month' }),
    value: [
      dayjs().subtract(1, 'month').startOf('month'),
      dayjs().subtract(1, 'month').endOf('month'),
    ],
  },
  {
    label: formatMessage({ id: 'date.last_7_days' }),
    value: [dayjs().subtract(7, 'days'), dayjs()],
  },
  {
    label: formatMessage({ id: 'date.last_30_days' }),
    value: [dayjs().subtract(30, 'days'), dayjs()],
  },
  {
    label: formatMessage({ id: 'date.this_year' }),
    value: [dayjs().startOf('year'), dayjs().endOf('year')],
  },
  {
    label: formatMessage({ id: 'date.last_year' }),
    value: [dayjs().subtract(1, 'year').startOf('year'), dayjs().subtract(1, 'year').endOf('year')],
  },
  {
    label: formatMessage({ id: 'date.last_year_value' }, { value: '1' }),
    value: [dayjs().subtract(1, 'year'), dayjs()],
  },
  {
    label: formatMessage({ id: 'date.last_year_value' }, { value: '2' }),
    value: [dayjs().subtract(2, 'year'), dayjs()],
  },
]

function RangePicker({ presets = [], ...props }) {
  const { formatMessage } = useIntl()
  const _presets = useMemo(() => {
    return [...getDefaultPresets(formatMessage), ...presets]
  }, [formatMessage, presets])

  return (
    <RangeAntd
      // getPopupContainer={getPopupContainer}
      placement="bottomLeft"
      format={formatDateText()}
      presets={_presets}
      {...props}
    />
  )
}

export default RangePicker
