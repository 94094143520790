import { message } from 'antd'
import { useMutation, useQuery } from 'react-query'
// import { useDispatch, useSelector } from 'react-redux'

import {
  getFinanceDelivery,
  getFinanceDeliverys,
  getMinTransDateFinanceDelivery,
  getTotalFinanceDeliveries,
  inputMassCreateFinanceDelivery,
} from 'utils/apis'
// import { optionsSelector } from 'redux/selectors'
// import { editOption } from 'redux/options/actions'

export const useDeliveries = ({
  payload = {},
  enabled = false,
  // saveDataPagination = true,
  options = {},
}) => {
  // const optionsState = useSelector(optionsSelector)
  // const dispatch = useDispatch()

  // if (saveDataPagination) {
  //   // prevent user that already setting per_page > 100
  //   if (payload.per_page > 100) {
  //     payload.per_page = 100
  //   }

  //   if (payload.per_page && payload.per_page !== optionsState.sales_per_page_default) {
  //     dispatch(
  //       editOption({
  //         sales_per_page_default: payload.per_page,
  //       }),
  //     )
  //   }
  // }

  return useQuery(
    ['delivery', payload],
    async () => {
      const {
        data: { data },
      } = await getFinanceDeliverys(payload)
      return data
    },
    {
      enabled,
      onError: (error) => {
        message.error(error?.message || 'Failed to load data from server!')
      },
      ...options,
    },
  )
}

export const useTotalDeliveries = (payload = {}, options = {}) => {
  return useQuery(
    ['total-deliveries', payload],
    async () => {
      const {
        data: { data },
      } = await getTotalFinanceDeliveries(payload)
      return data
    },
    {
      onError: (error) => {
        message.error(error?.message || 'Failed to load data from server!')
      },
      ...options,
    },
  )
}

export function useDelivery({ id, payload = {}, enabled = false }) {
  return useQuery(
    ['delivery', id, payload],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getFinanceDelivery(id, payload)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      enabled: !!id && enabled,
      onError: (error) => message.error(error?.message || 'Failed to load data from server!'),
    },
  )
}

export const useGetMinTransDateFinanceDeliveryMutation = () => {
  return useMutation((payload) =>
    getMinTransDateFinanceDelivery({ ...payload, trans_type: 'delivery' }),
  )
}

export const useInputMassCreateFinanceDeliveryMutation = () => {
  return useMutation(inputMassCreateFinanceDelivery)
}
