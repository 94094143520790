import { message } from 'antd'
import { useMutation, useQuery } from 'react-query'

import {
  executeImportFinancePurchaseQuote,
  getFinancePurchaseQuotes,
  getTotalPurchaseQuotes,
  uploadImportFinancePurchaseQuote,
} from 'utils/apis'
// import { useDispatch, useSelector } from 'react-redux'
// import { optionsSelector } from 'redux/selectors'
// import { editOption } from 'redux/options/actions'

export const usePurchaseQuotes = ({ payload = {}, enabled = false }) => {
  // const options = useSelector(optionsSelector)
  // const dispatch = useDispatch()

  // // prevent user that already setting per_page > 100
  // if (payload.per_page > 100) {
  //   payload.per_page = 100
  // }

  // if (payload.per_page && payload.per_page !== options.purchases_per_page_default) {
  //   dispatch(
  //     editOption({
  //       purchases_per_page_default: payload.per_page,
  //     }),
  //   )
  // }

  return useQuery(
    ['purchaseQuote', payload],
    async () => {
      const {
        data: { data },
      } = await getFinancePurchaseQuotes(payload)
      return data
    },
    {
      enabled,
      onError: (error) => {
        message.error(error?.message || 'Failed to load data from server!')
      },
    },
  )
}

export const useTotalPurchaseQuotes = (payload, options = {}) => {
  return useQuery(
    ['total-purchase-quotes', payload],
    async () => {
      const {
        data: { data },
      } = await getTotalPurchaseQuotes(payload)
      return data
    },
    {
      onError: (error) => {
        message.error(error?.message || 'Failed to load data from server!')
      },
      ...options,
    },
  )
}

export function useUploadImportFinancePurchaseQuoteMutation() {
  return useMutation(uploadImportFinancePurchaseQuote)
}

export function useExecuteImportFinancePurchaseQuoteMutation() {
  return useMutation(executeImportFinancePurchaseQuote)
}
