import { message } from 'antd'
import { useMutation, useQuery } from 'react-query'
// import { useDispatch, useSelector } from 'react-redux'

import {
  executeImportFinanceQuote,
  getFinanceQuotes,
  uploadImportFinanceQuote,
  getTotalFinanceQuotes,
} from 'utils/apis'
// import { optionsSelector } from 'redux/selectors'
// import { editOption } from 'redux/options/actions'

export const useQuotes = ({
  payload = {},
  enabled = false,
  // saveDataPagination = true,
  options = {},
}) => {
  // const optionsState = useSelector(optionsSelector)
  // const dispatch = useDispatch()

  // if (saveDataPagination) {
  //   // prevent user that already setting per_page > 100
  //   if (payload.per_page > 100) {
  //     payload.per_page = 100
  //   }

  //   if (payload.per_page && payload.per_page !== optionsState.sales_per_page_default) {
  //     dispatch(
  //       editOption({
  //         sales_per_page_default: payload.per_page,
  //       }),
  //     )
  //   }
  // }

  return useQuery(
    ['quote', payload],
    async () => {
      const {
        data: { data },
      } = await getFinanceQuotes(payload)
      return data
    },
    {
      enabled,
      onError: (error) => {
        message.error(error?.message || 'Failed to load data from server!')
      },
      ...options,
    },
  )
}

export const useTotalQuotes = (payload, options = {}) => {
  return useQuery(
    ['total-quotes', payload],
    async () => {
      const {
        data: { data },
      } = await getTotalFinanceQuotes(payload)
      return data
    },
    {
      onError: (error) => {
        message.error(error?.message || 'Failed to load data from server!')
      },
      ...options,
    },
  )
}

export function useUploadImportFinanceQuoteMutation() {
  return useMutation(uploadImportFinanceQuote)
}

export function useExecuteImportFinanceQuoteMutation() {
  return useMutation(executeImportFinanceQuote)
}
